import PropTypes from 'prop-types'
import React, { useState } from 'react'
import ErrorBoundary from './ErrorBoundary'
import Tooltip from 'reactstrap/lib/Tooltip'
import Dropdown from 'reactstrap/lib/Dropdown'
import DropdownItem from 'reactstrap/lib/DropdownItem'
import DropdownMenu from 'reactstrap/lib/DropdownMenu'
import DropdownToggle from 'reactstrap/lib/DropdownToggle'

import './WidgetCard.scss'

const WIDGET_SIZE = {
  HEIGHT: {
    SMALL: 'smallHeight',
    MEDIUM: 'mediumHeight',
    LARGE: 'largeHeight',
    INFINITE: 'infiniteHeight'
  }
}

function WidgetCard({
  children,
  className,
  errorMessage,
  id,
  footer,
  footerTooltip,
  height,
  subtitle,
  subtitle2,
  title,
  menuOptions,
  allowOneMenuOptionDropDown,
  skipErrorBoundary
}) {
  const [isFooterTooltipVisible, setFooterTooltipVisible] = useState(false)
  const [isMenuOptionsOpen, setIsMenuOptionsOpen] = useState(false)

  const toggleMenuOptions = () => {
    setIsMenuOptionsOpen(o => !o)
  }

  const renderMenuOptions = menuOptions => {
    return (
      <Dropdown
        active={menuOptions ? true : false}
        data-testid="more-menu-options"
        className="more-menu"
        isOpen={isMenuOptionsOpen}
        toggle={toggleMenuOptions}>
        {allowOneMenuOptionDropDown !== true && menuOptions.length === 1 ? (
          <DropdownToggle
            nav
            data-testid="menu-options-one-choice"
            role="button"
            tabIndex={0}
            onKeyDown={e => {
              if (e.keyCode === 13 || e.keyCode === 32) {
                menuOptions[0].onClickFn()
                return false
              }
            }}>
            <button
              className="three-dots-btn"
              data-testid="three-dots-btn"
              title={menuOptions[0].displayText}
              onClick={menuOptions[0].onClickFn}>
              ...
            </button>
          </DropdownToggle>
        ) : (
          <div>
            <DropdownToggle nav data-testid="menu-options-dd-toggle" role="button">
              <button className="three-dots-btn" title="Menu options" data-testid="three-dots-btn">
                ...
              </button>
            </DropdownToggle>
            <DropdownMenu className="pt-0 shadow-lg more-menu-options" data-testid="menu-options-dd-menu">
              {menuOptions.map(item => {
                return (
                  <DropdownItem key={item.displayText} data-testid="menu-options-dd-item" onClick={item.onClickFn}>
                    {item.displayText}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </div>
        )}
      </Dropdown>
    )
  }

  const renderWidgetNode = () => (
    <div className={`widget-card mr-1 mb-1 ${height} ${className}`}>
      <div className="title-container">
        <div className="main-title-container">
          <div>{title}</div>
          {menuOptions && renderMenuOptions(menuOptions)}
        </div>
        <div className="subtitles-container">
          {subtitle ? <div className="subtitle-primary">{subtitle}</div> : null}
          {subtitle2 ? <div className="subtitle-secondary">{subtitle2}</div> : null}
        </div>
      </div>

      <div className="child-container">{children}</div>

      {footer && (
        <>
          <div className="footer" id={`footer_${id}`}>
            {footer}
          </div>
          {footerTooltip && (
            <Tooltip
              placement="bottom"
              isOpen={isFooterTooltipVisible}
              target={`footer_${id}`}
              toggle={() => setFooterTooltipVisible(!isFooterTooltipVisible)}>
              {footerTooltip}
            </Tooltip>
          )}
        </>
      )}
    </div>
  )

  if (skipErrorBoundary) {
    return renderWidgetNode()
  }

  return <ErrorBoundary errorMessage={errorMessage}>{renderWidgetNode()}</ErrorBoundary>
}

WidgetCard.propTypes = {
  allowOneMenuOptionDropDown: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  footer: PropTypes.string,
  footerTooltip: PropTypes.string,
  height: PropTypes.oneOf(Object.values(WIDGET_SIZE.HEIGHT)),
  id: PropTypes.string.isRequired,
  menuOptions: PropTypes.arrayOf(PropTypes.shape({ displayText: PropTypes.string, onClickFn: PropTypes.func })),
  skipErrorBoundary: PropTypes.bool,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  title: PropTypes.string
}

WidgetCard.defaultProps = {
  className: '',
  errorMessage: 'Widget has encountered an error',
  height: WIDGET_SIZE.HEIGHT.SMALL,
  skipErrorBoundary: false
}

export default WidgetCard
export { WIDGET_SIZE }
