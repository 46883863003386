import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'reactstrap/lib/Alert'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
      error,
      errorInfo
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="tracker-widget-error-boundary container-fluid p-4">
          <Alert color="danger">
            <h3 className="page-heading my-2 text-center">{this.props.errorMessage}</h3>
            <p>{this.state.error && this.state.error.toString() + this.state.errorInfo.componentStack}</p>
          </Alert>
        </div>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.string
}
