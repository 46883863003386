import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import WidgetCard, { WIDGET_SIZE } from '../WidgetCard/WidgetCard'
import LoadError from '../../LoadError/LoadError'
import buildNavMenuOptions from './helpers/buildNavMenuOptions'

function WidgetLoadError({ strings, logger, id, className, height, navMenuOptions, baseUrl }) {
  const menuOptions = useMemo(() => {
    return buildNavMenuOptions(baseUrl, navMenuOptions)
  }, [navMenuOptions, baseUrl])

  return (
    <WidgetCard
      id={`widget-load-error-${id}`}
      title={strings.widgetTitle}
      height={height}
      menuOptions={menuOptions}
      allowOneMenuOptionDropDown={true}>
      <LoadError strings={strings} logger={logger} className={className}></LoadError>
    </WidgetCard>
  )
}

WidgetLoadError.propTypes = {
  baseUrl: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOf(Object.values(WIDGET_SIZE.HEIGHT)),
  id: PropTypes.string.isRequired,
  logger: PropTypes.shape({
    critical: PropTypes.func,
    error: PropTypes.func,
    info: PropTypes.func,
    warn: PropTypes.func,
    verbose: PropTypes.func
  }),
  navMenuOptions: PropTypes.object,
  strings: PropTypes.shape({
    displayMessage: PropTypes.string,
    detailMessage: PropTypes.string,
    widgetTitle: PropTypes.string
  }).isRequired
}

export default WidgetLoadError
