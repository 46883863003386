import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import './LoadError.scss'
import loadErrorImage1 from './resources/images/loadErrorImage1.png'
import loadErrorImage2 from './resources/images/loadErrorImage2.png'
import loadErrorImage3 from './resources/images/loadErrorImage3.png'
import loadErrorImage4 from './resources/images/loadErrorImage4.png'
import loadErrorImage5 from './resources/images/loadErrorImage5.png'

function LoadError({ strings, logger, className }) {
  let images = []
  let index = 0
  images[0] = loadErrorImage1
  images[1] = loadErrorImage2
  images[2] = loadErrorImage3
  images[3] = loadErrorImage4
  images[4] = loadErrorImage5
  index = Math.floor(Math.random() * images.length)

  useEffect(() => {
    logger && logger.error(new Error('Load Error : ' + strings ? strings.detailMessage || strings.displayMessage : ''))
  }, [logger, strings])

  return (
    <div className={`col-12 sdk-text-primary gtt-load-error ${className}`}>
      <h5>{strings && strings.displayMessage}</h5>
      <div className="load-error-img" style={{ backgroundImage: `url(${images[index]})` }} />
    </div>
  )
}

LoadError.propTypes = {
  className: PropTypes.string,
  logger: PropTypes.shape({
    critical: PropTypes.func,
    error: PropTypes.func,
    info: PropTypes.func,
    warn: PropTypes.func,
    verbose: PropTypes.func
  }),
  strings: PropTypes.shape({
    displayMessage: PropTypes.string,
    detailMessage: PropTypes.string
  }).isRequired
}

export default LoadError
