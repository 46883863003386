export default function(baseUrl, navMenuOptions) {
  if (!(baseUrl && navMenuOptions)) return null
  let menuOptionConfig = Object.keys(navMenuOptions)
  if (!menuOptionConfig.length) return null

  let menuOptions = menuOptionConfig
    .map(key => {
      let navPath = navMenuOptions[key].path
      if (!navPath) return false

      return {
        displayText: navMenuOptions[key].title,
        onClickFn: () => {
          try {
            //qualified path
            let navUrl = new URL(navPath)
            global.location = navUrl
          } catch (e) {
            //handle relative path
            global.history.pushState('', '', `${baseUrl}${navMenuOptions[key].path}`)
          }
        }
      }
    })
    .filter(Boolean)

  return menuOptions
}
